import Flatpickr from "stimulus-flatpickr"
import "flatpickr/dist/themes/light.css"
import * as R from "ramda"

export default class extends Flatpickr {
  config: object

  static targets = ["from", "to"]

  declare fromTarget: HTMLInputElement
  declare toTarget: HTMLInputElement
  declare fp: any // the flatpickr instance

  // NOTE: When navigating back and forward on a page, the turbo lifecylce includes 2 render cycles
  //       This causes a flash of the "previous" pages' flatpickr input as the new page gets stood up.
  //       See: https://github.com/hotwired/turbo/issues/132 for a bit of discussion.
  //       Will need to use turbo:action to handle this.
  connect() {
    this.config = {
      disableMobile: true,
      minuteIncrement: 30,
    }
    super.connect()
  }

  change = (selectedDates, str, instance) => {
    if (R.length(selectedDates) === 2) {
      // NOTE: Range Selection
      let [from, to] = selectedDates
      this.fromTarget.value = from.toISOString()
      this.toTarget.value = to.toISOString()
      instance.input.removeAttribute("name")
    }
  }

  removeDate = () => {
    this.fp.clear()
  }
}
