import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["jobCheckbox", "cancelWarningMessage", "input", "cancelButton", "repostButton"]

  declare jobCheckboxTargets: HTMLInputElement[]
  declare cancelWarningMessageTarget: HTMLElement
  declare hasCancelWarningMessageTarget: boolean
  declare inputTarget: HTMLInputElement
  declare cancelButtonTarget: HTMLButtonElement
  declare repostButtonTarget: HTMLButtonElement

  toggleJob(e) {
    this.jobCheckboxTargets.forEach((checkbox) => {
      if (e.currentTarget.contains(checkbox)) {
        checkbox.checked = !checkbox.checked
      }
    })
    this.toggleWarningMessage()
    this.validateMultiDayCancellation()
  }

  selectAllJobs() {
    this.jobCheckboxTargets.forEach((checkbox) => {
      checkbox.parentElement.classList.add("ParentsBookings-JobBlock--cancel")
      checkbox.checked = true
    })

    this.toggleWarningMessage()
    this.validateMultiDayCancellation()
  }

  toggleWarningMessage() {
    if (this.hasCancelWarningMessageTarget) {
      const showCancelWarning = this.jobCheckboxTargets.some(
        (checkbox) => checkbox.checked && checkbox.dataset.cancelCharge === "true"
      )

      if (showCancelWarning) {
        this.cancelWarningMessageTarget.classList.remove("d-none")
      } else {
        this.cancelWarningMessageTarget.classList.add("d-none")
      }
    }
  }

  validateMultiDayCancellation() {
    const input = this.inputTarget.value
    const isValid =
      this.inputTarget.type == "hidden"
        ? this.jobCheckboxTargets.some((checkbox) => checkbox.checked)
        : this.jobCheckboxTargets.some((checkbox) => checkbox.checked) && input.length > 5
    this.cancelButtonTarget.disabled = !isValid
    this.repostButtonTarget.disabled = !isValid
  }

  validateSingleDayCancellation() {
    const input = this.inputTarget.value
    this.cancelButtonTarget.disabled = input.length < 5
    this.repostButtonTarget.disabled = input.length < 5
  }
}
