import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "tabPanel"]

  declare tabTargets: HTMLElement[]
  declare tabPanelTargets: HTMLElement[]

  initialize() {
    this.showTab()
  }

  change(e) {
    e.preventDefault()
    this.index = this.tabTargets.indexOf(e.target.closest("a").parentNode)
  }

  showTab() {
    this.tabPanelTargets.forEach((el, i) => {
      if (i == this.index) {
        el.classList.remove("d-none")
      } else {
        el.classList.add("d-none")
      }
    })

    this.tabTargets.forEach((el, i) => {
      if (i == this.index) {
        el.classList.add("tab--active")
      } else {
        el.classList.remove("tab--active")
      }
    })
  }

  get index() {
    return parseInt(this.data.get("index"))
  }

  set index(value) {
    this.data.set("index", value.toString())
    this.showTab()
  }
}
