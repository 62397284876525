import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import * as R from "ramda"

export default class extends Controller {
  static targets = ["results", "date", "input", "submit"]
  static values = { url: String }

  declare resultsTarget: HTMLElement
  declare dateTargets: HTMLInputElement[]
  declare inputTarget: HTMLInputElement
  declare submitTarget: HTMLButtonElement

  multi(event: Event) {
    const target = event.target as HTMLSelectElement
    Turbo.visit(`/booking/multi/new?days=${target.value}`)
  }

  async assignDate() {
    const dates = this.dateTargets.map((el) => el.value)
    const url = "/booking/preview/assign_dates"

    // JSON request to update the submit button to target the model or submit the form
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CSRF-Token": document.head.querySelector("[name='csrf-token']").content,
      },
      body: JSON.stringify({ dates }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!R.isEmpty(data.dates_with_existing_job)) {
          if (this.submitTarget.type == "submit") {
            this.submitTarget.type = "button"
            this.submitTarget.removeAttribute("data-turbo")
            this.submitTarget.setAttribute("data-bs-toggle", "modal")
            this.submitTarget.setAttribute("data-bs-target", "#confirmAdditionalJobForDateModal")
          }

          // Turbo request to update the confirm modal message
          fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "text/vnd.turbo-stream.html",
              "X-CSRF-Token": document.head.querySelector("[name='csrf-token']").content,
            },
            body: JSON.stringify({ dates }),
          })
            .then((response) => response.text())
            .then((html) => Turbo.renderStreamMessage(html))
        } else if (R.isEmpty(data.dates_with_existing_job) && this.submitTarget.type == "button") {
          this.submitTarget.type = "submit"
          this.submitTarget.removeAttribute("data-bs-toggle")
          this.submitTarget.removeAttribute("data-bs-target")
          this.submitTarget.setAttribute("data-turbo", "false")
        }
      })
  }
}
