import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { rating: Number }
  static targets = ["star", "input"]
  static classes = ["rated"]

  declare starTargets: HTMLElement[]
  declare hasStarTargets: Boolean
  declare inputTarget: HTMLInputElement
  declare ratedClass: string

  connect() {
    if (this.hasStarTargets) {
      this._updateStars()
    }
  }

  setStar(event: Event) {
    const element = event.target as HTMLElement
    const value = element.dataset.value

    this.inputTarget.value = value
    this._updateStars()
  }

  _updateStars() {
    const value = this.inputTarget.value

    this.starTargets.forEach((e) => {
      e.classList.remove(this.ratedClass)
      if (e.dataset.value <= value) {
        e.classList.add(this.ratedClass)
      }
    })
  }
}
