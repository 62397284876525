import { Controller } from "@hotwired/stimulus"

interface SelectedEvent extends CustomEvent {
  detail: MutationRecord
}

export default class extends Controller {
  static values = { counter: String }
  static targets = ["content"]

  declare contentTarget: HTMLElement
  declare counterValue: string

  update(event: SelectedEvent) {
    const oldCounter = parseInt(this.counterValue)

    if (event.detail.oldValue) {
      this.counterValue = (parseInt(this.counterValue) - 1).toString()
    } else {
      this.counterValue = (parseInt(this.counterValue) + 1).toString()
    }

    const toggleController: any = this.application.getControllerForElementAndIdentifier(
      this.element,
      "toggle"
    )

    if (oldCounter === 0 && parseInt(this.counterValue) >= 1) {
      toggleController.toggle()
    } else if (oldCounter >= 1 && parseInt(this.counterValue) === 0) {
      toggleController.toggle()
    }

    this.contentTarget.innerHTML = this.counterValue
  }
}
