import Modal from "bootstrap/js/dist/modal"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  private boundOnChangeLocationType

  static targets = [
    "locationType",
    "street",
    "unit",
    "postal",
    "city",
    "neighbourhood",
    "modal",
    "errorText",
  ]

  declare locationTypeTarget: HTMLInputElement
  declare streetTarget: HTMLInputElement
  declare hasStreetTarget: Boolean
  declare unitTarget: HTMLInputElement
  declare postalTarget: HTMLInputElement
  declare cityTarget: HTMLSelectElement
  declare neighbourhoodTarget: HTMLInputElement
  declare modalTarget: HTMLElement
  declare errorTextTarget: HTMLElement
  declare google: google
  declare autoComplete: google.maps.places.Autocomplete

  modal: Modal

  initialize() {
    this.boundOnChangeLocationType = this.onChangeLocationType.bind(this)
  }

  connect() {
    if (typeof google != "undefined") {
      this.initMap()
    }
    this.modal = new Modal(this.modalTarget)
    this.locationTypeTarget.addEventListener("change", this.boundOnChangeLocationType)
  }

  disconnect() {
    this.locationTypeTarget.removeEventListener("change", this.boundOnChangeLocationType)
  }

  initMap() {
    if (this.hasStreetTarget) {
      this.autoComplete = new google.maps.places.Autocomplete(this.streetTarget)
      this.autoComplete.setFields(["address_components"])
      this.autoComplete.setComponentRestrictions({
        country: ["ca"],
      })
      this.autoComplete.addListener("place_changed", this.placeChanged.bind(this))
    }
  }

  onChangeLocationType(e) {
    const neighbourhoodLabelEl = this.neighbourhoodTarget.labels[0]
    if (["hotel", "vacation_rental"].includes(e.target.value)) {
      neighbourhoodLabelEl.innerHTML = `${e.target.value.replace("_", " ")} name`
      neighbourhoodLabelEl.classList.add("text-capitalize")
    } else {
      neighbourhoodLabelEl.innerHTML = "Cross Street or Neighborhood"
    }
  }

  async closeModal(event) {
    event.preventDefault()
    await this.modal.hide()
  }

  async placeChanged() {
    if (this.isValidCity()) {
      this.fillInAddress()
    } else {
      this.streetTarget.value = ""
      this.errorTextTarget.textContent = `We do not currently service ${this.cityComponent.long_name}, ${this.provinceComponent.long_name}.`
      await this.modal.show()
      console.log("We don't service there")
    }
    console.log(this.place)
  }

  isValidCity(): boolean {
    const cityComponent = this.cityComponent
    const cities = JSON.parse(this.data.get("cities"))
    return cities.map((c) => c[1]).includes(cityComponent.long_name)
  }

  fillInAddress() {
    // Get the place details from the autocomplete object.
    const place = this.place
    let address1 = ""
    let postcode = ""

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    // place.address_components are google.maps.GeocoderAddressComponent objects
    // which are documented at http://goo.gle/3l5i5Mr
    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0]

      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name} ${address1}`
          break
        }

        case "route": {
          address1 += component.short_name
          break
        }

        case "postal_code": {
          postcode = `${component.long_name}${postcode}`
          break
        }

        case "postal_code_suffix": {
          postcode = `${postcode}-${component.long_name}`
          break
        }

        case "sublocality_level_1": {
          this.neighbourhoodTarget.value = component.long_name
          break
        }

        case "locality":
          this.setCity(component.long_name)
          break
      }
    }

    this.streetTarget.value = address1
    // this.streetTarget.disabled = true
    this.postalTarget.value = postcode
    // this.postalTarget.disabled = true

    // After filling the form with address components from the Autocomplete
    // prediction, set cursor focus on the second address line to encourage
    // entry of subpremise information such as apartment, unit, or floor number.
    this.unitTarget.focus()
  }

  setCity(cityName) {
    const options = JSON.parse(this.data.get("cities"))
    const option = Array.from(options).find((o) => o[1] == cityName)

    // hack to select the choices.js option
    // https://github.com/Choices-js/Choices/issues/376#issuecomment-591082251
    let selectFields = this.cityTarget
      .closest(".choices")
      .querySelectorAll(".choices__item--selectable")
    selectFields.forEach((el) => {
      if (el.dataset.value == option[0]) {
        el.dispatchEvent(new Event("mousedown"))
      }
    })
  }

  public get place(): google.maps.places.PlaceResult {
    return this.autoComplete.getPlace()
  }

  public get cityComponent(): google.maps.GeocoderAddressComponent {
    return this.place.address_components.find((e) => e.types.includes("locality"))
  }

  public get provinceComponent(): google.maps.GeocoderAddressComponent {
    return this.place.address_components.find((e) =>
      e.types.includes("administrative_area_level_1")
    )
  }
}
