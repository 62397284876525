import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "icon"]

  declare iconTarget: HTMLElement
  declare inputTarget: HTMLInputElement

  connect() {
    if (this.inputTarget) this.toggleInputIcon()
  }

  handleFocus = (event: MouseEvent) => {
    this.iconTarget.style.display = "none"
  }

  handleBlur() {
    this.toggleInputIcon()
  }

  toggleInputIcon() {
    this.iconTarget.style.display = this.inputTarget.value.length !== 0 ? "none" : "block"
  }
}
