import { Controller } from "@hotwired/stimulus"
import * as R from "ramda"
import { SELECTED_EVENT } from "./invoker_controller"

export interface StimulusHTMLElement extends HTMLElement {
  controller: any
}

export default class RadioSelectorController extends Controller {
  private optionSelectedEvent: CustomEvent

  static targets = ["option", "input", "radioButton"]
  static values = { on: String }

  declare inputTarget: HTMLInputElement
  declare optionTargets: HTMLElement[]
  declare onValue: string
  declare radioButtonTargets: HTMLInputElement[]

  public static readonly SELECTED: string = "SELECTED"

  initialize() {
    this.optionSelectedEvent = new CustomEvent(SELECTED_EVENT, {
      bubbles: true,
    })
  }

  select = (event: MouseEvent) => {
    this.radioButtonTargets.forEach((radioButton) => {
      // @ts-ignore
      if (event.currentTarget.contains(radioButton)) {
        radioButton.checked = true
      } else {
        radioButton.checked = false
      }
    })

    this.optionTargets.forEach((elm: StimulusHTMLElement) => {
      elm.controller = this.application.getControllerForElementAndIdentifier(elm, this.onValue)

      if (elm.contains(event.currentTarget as Node)) {
        // NOTE: Case (1), when the clicked element is a child of the parent option element
        if (R.not(this.isSelected(elm))) {
          elm.toggleAttribute(RadioSelectorController.SELECTED)
          elm.controller.toggle()
          elm.dispatchEvent(this.optionSelectedEvent)
          this.inputTarget.value = elm.dataset.radioSelectorValue
          this.inputTarget.dispatchEvent(new Event("change"))
        }
      } else {
        // NOTE: Case (2), when the clicked element is NOT a child of the parent option element,
        //       we need to check if it is already SELECTED, if it is we don't want to re-toggle
        if (this.isSelected(elm)) {
          elm.controller[this.onValue]()
          elm.dispatchEvent(this.optionSelectedEvent)
          elm.removeAttribute(RadioSelectorController.SELECTED)
        }
      }
    })
  }

  isSelected = (elm: StimulusHTMLElement) => R.isEmpty(elm.getAttribute("selected"))
}
