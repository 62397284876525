import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["frame"]
  declare frameTarget: HTMLElement

  handleClick(event: MouseEvent) {
    event.preventDefault()

    if (this.frameTarget.dataset.newRecord === "true") {
      this.frameTarget.remove()
    } else {
      const input: HTMLInputElement = this.frameTarget.querySelector("input[name*='_destroy']")
      input.value = "1"
      this.frameTarget.style.display = "none"
    }
  }
}
