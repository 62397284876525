import "controllers"
import "@hotwired/turbo-rails"
import Rails from "@rails/ujs"
import "@popperjs/core"
import "bootstrap/js/dist/collapse"
import "bootstrap/js/dist/offcanvas"
import "bootstrap/js/dist/modal"
import "bootstrap/js/dist/dropdown"
import "bootstrap/js/dist/popover"
import "./custom-bootstrap"
Rails.start()

document.addEventListener("turbo:before-cache", () => {
  const flashMessageElement = document.getElementById("flash")
  if (flashMessageElement) {
    flashMessageElement.remove()
  }
})

//temporary hack since it seems _top is not causing the turboframe to reload on back.
document.addEventListener("turbo:submit-end", (event) => {
  const target = event.target
  //@ts-ignore
  const someAttribute = target.querySelector(`[data-enable-on-complete-for-back]`)
  if (someAttribute?.disabled) {
    someAttribute.disabled = false
    someAttribute.innerHTML = `<span class='text-white' >${someAttribute.textContent}</>`
  }
})

window.initMap = function (...args) {
  const event = new Event("Places", { bubbles: true, cancelable: false })
  window.dispatchEvent(event)
}
