import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  private boundOnSubmit

  static targets = ["form", "submitButton"]

  declare formTarget: HTMLFormElement
  declare submitButtonTarget: HTMLButtonElement

  initialize() {
    this.boundOnSubmit = this.onSubmit.bind(this)
  }

  connect() {
    this.formTarget.addEventListener("submit", this.boundOnSubmit)
  }

  disconnect(): void {
    this.formTarget.removeEventListener("submit", this.boundOnSubmit)
  }

  onSubmit(e) {
    this.submitButtonTarget.disabled = true
    this.submitButtonTarget.firstElementChild.classList.toggle("visually-hidden")
  }
}
