import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "placeholder"]

  selection = (event: Event) => {
    // @ts-ignore
    event.currentTarget.closest("form").dispatchEvent(new CustomEvent("submit", { bubbles: true }))
  }
}
