import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"

const TIP_WARNING_THRESHOLD = 30

export default class InvoiceController extends Controller {
  static values = { total: String }
  static targets = [
    "total",
    "tip",
    "tipPercent",
    "additionalChildren",
    "afterMidnight",
    "statHoliday",
    "overtime",
    "overtime2x",
    "confirmTotal",
  ]

  declare total: string
  declare totalTarget: HTMLElement
  declare confirmTotalTarget: HTMLElement
  declare tipTarget: HTMLElement
  declare tipPercentTarget: HTMLElement
  declare hasTipPercentTarget: Boolean

  async recalculate() {
    //call update based on new values
    const invoiceFormData = new FormData(this.element as HTMLFormElement)
    const jobId = this.element.getAttribute("data-job-id")
    const tip = invoiceFormData.get("parent_invoice_form[line_items_tips_rate]") as string
    const subtotal = this.data.get("subtotal")
    const tipPercent = Math.round((parseFloat(tip) / parseFloat(subtotal)) * 100)
    const showTipWarning = tipPercent > TIP_WARNING_THRESHOLD
    const searchParams = { id: jobId }
    invoiceFormData.forEach((value, key) => {
      if (key != "authenticity_token") {
        searchParams[key] = value
      }
    })

    if (this.hasTipPercentTarget) {
      this.tipPercentTarget.innerHTML = tipPercent ? `${tipPercent}%` : null
      if (showTipWarning) {
        this.tipPercentTarget.classList.add("text-warning")
        this.tipTarget.classList.add("border", "border-2", "border-warning")
      } else {
        this.tipTarget.classList.remove("border", "border-2", "border-warning")
        this.tipPercentTarget.classList.remove("text-warning")
      }
    }

    const url = `/invoices/recalculate?${new URLSearchParams(searchParams).toString()}`
    const response = await fetch(url, { headers: { accept: "application/json" } })
    const data = await response.json()
    this.totalTarget.innerHTML = data.total
    this.confirmTotalTarget.innerHTML = data.total
  }

  show_line_item(event) {
    event.preventDefault()
    this[`${event.params.lineItem}Target`].classList.remove("d-none")
  }

  hide_line_item(event) {
    event.preventDefault()

    // hide line item
    const target = this[`${event.params.lineItem}Target`]
    target.classList.add("d-none")

    // clear input values
    const inputs = target.getElementsByTagName("input")
    for (let i = 0; i < inputs.length; ++i) {
      const inputNode = inputs[i]
      inputNode.value = ""
    }
  }
}
