import { Instance } from "flatpickr/dist/types/instance"
import * as R from "ramda"
import Offcanvas from "bootstrap/js/dist/offcanvas"
import { Controller } from "@hotwired/stimulus"

interface FlatpickrHTMLInputElement extends HTMLInputElement {
  _flatpickr: Instance
}

export default class NannyAvailabilityController extends Controller {
  static targets = [
    "availabilities",
    "availabilitiesSave",
    "addTimepicker",
    "availabilityType",
    "fromTimepicker",
    "toTimepicker",
    "availableTimeRadio",
    "timepickerContainer",
    "timepickerInputContainer",
    "availabilityId",
    "deleteAvailabilityRange",
    "timepickerRemoveIcon",
    "errors",
  ]
  declare availabilitiesSaveTarget: HTMLInputElement
  declare addTimepickerTarget: HTMLElement
  declare availabilityTypeTargets: Array<HTMLInputElement>
  declare fromTimepickerTargets: Array<FlatpickrHTMLInputElement>
  declare toTimepickerTargets: Array<FlatpickrHTMLInputElement>
  declare availableTimeRadioTargets: HTMLInputElement
  declare timepickerContainerTargets: Array<HTMLInputElement>
  declare timepickerInputContainerTargets: Array<HTMLInputElement>
  declare availabilityIdTargets: Array<HTMLInputElement>
  declare deleteAvailabilityRangeTargets: Array<HTMLInputElement>
  declare timepickerRemoveIconTargets: Array<HTMLInputElement>
  declare errorsTarget: HTMLElement

  offcanvas: Offcanvas

  async connect() {
    this.offcanvas = new Offcanvas(this.element)
    await this.offcanvas.show()
  }
  // "public" methods

  showTimepicker() {
    if (!this.validTimeRange) return

    const idx = R.findIndex(R.equals(this.hiddenTimepicker))(this.timepickerContainerTargets)

    // side effects
    this.toggleDestroy(idx)
    this.toggleAvailabilityTypePicker()
    this.toggleAddTimepicker()
    this.enableTimepickers()

    // show the timepicker
    this.hiddenTimepicker.hidden = false
    if (this.timepickerRemoveIconTargets[idx]) this.timepickerRemoveIconTargets[idx].hidden = false

    this.setTimeConstraints()
    this.resetTimepicker(idx)
    this.checkValidations()
  }

  hideTimepicker = (event) => {
    const idx = event.currentTarget.dataset.index

    // side effects
    if (!R.isNil(this.availabilityIdTargets[idx]?.value)) {
      this.deleteAvailabilityRangeTargets[idx].value = "true"
      this.hideTimepickerRemoveIcons()
    }
    this.toggleAvailabilityTypePicker(idx)
    this.toggleAddTimepicker()
    this.resetTimepicker(idx)

    // hide the timepicker
    this.timepickerContainerTargets[idx].hidden = true

    this.checkValidations()
  }

  toggleTimepickers() {
    const anytimeSelected = this.availableTimeRadioTargets[0].checked
    anytimeSelected ? this.disableTimepickers() : this.enableTimepickers()
  }

  checkValidations() {
    const anytimeSelected = this.availableTimeRadioTargets[0].checked
    if (!anytimeSelected && !this.validTimeRange) {
      this.disableSaveButton()
      this.showErrorMessage()
    } else {
      this.enableSaveButton()
      this.hideErrorMessage()
    }
  }

  // "private" methods used within this class

  get hiddenTimepicker() {
    const hiddenPicker = R.find(
      (el) => !R.isNil(el.getAttribute("hidden")),
      this.timepickerContainerTargets
    )
    return hiddenPicker
  }

  get validTimeRange() {
    return this.allTimeRangesAreFilled && this.endTimeGreaterThanStart && !this.timeRangesOverlap
  }

  private showErrorMessage() {
    if (this.allTimeRangesAreFilled) this.errorsTarget.hidden = false
  }

  private hideErrorMessage() {
    this.errorsTarget.hidden = true
  }
  get allTimeRangesAreFilled() {
    const isFilled = (el, idx) => {
      return !this.timepickerIsVisible(idx) || !R.isEmpty(el.value)
    }
    return (
      this.allWithIndex(isFilled, this.fromTimepickerTargets) &&
      this.allWithIndex(isFilled, this.toTimepickerTargets)
    )
  }

  get endTimeGreaterThanStart() {
    return this.allWithIndex(
      (startEl, idx) =>
        !this.timepickerIsVisible(idx) ||
        (startEl.value &&
          this.toTimepickerTargets[idx].value &&
          startEl.value < this.toTimepickerTargets[idx].value),
      this.fromTimepickerTargets
    )
  }

  get timeRangesOverlap() {
    if (this.multipleTimepickersVisible) {
      return this.toTimepickerTargets[0].value > this.fromTimepickerTargets[1].value
    } else {
      return false
    }
  }

  allWithIndex = R.addIndex(R.all)

  private timepickerIsVisible(idx) {
    return this.timepickerContainerTargets[idx] && !this.timepickerContainerTargets[idx].hidden
  }

  get multipleTimepickersVisible() {
    return (
      this.timepickerContainerTargets.length > 1 &&
      R.all((el) => !el.hidden, this.timepickerContainerTargets)
    )
  }

  get multipleAvailabilityTypePickersVisible() {
    return (
      this.availabilityTypeTargets.length > 1 &&
      R.all((el) => el.hidden == false, this.availabilityTypeTargets)
    )
  }

  get noAvailabilityTypePickerVisible() {
    return R.all((el) => el.hidden == true, this.availabilityTypeTargets)
  }

  private toggleDestroy(idx) {
    const destroyInput = this.deleteAvailabilityRangeTargets[idx]
    if (destroyInput) destroyInput.value = (!destroyInput.value).toString()
  }

  private toggleAvailabilityTypePicker(hiddenTimepickerIdx?) {
    if (this.multipleAvailabilityTypePickersVisible) {
      this.availabilityTypeTargets[1].hidden = true
    } else if (hiddenTimepickerIdx && this.noAvailabilityTypePickerVisible) {
      // show the availability type picker for the VISIBLE timepicker
      this.availabilityTypeTargets[hiddenTimepickerIdx == 1 ? 0 : 1].hidden = false
    }
  }

  private toggleAddTimepicker() {
    this.addTimepickerTarget.hidden = !this.addTimepickerTarget.hidden
  }

  private resetTimepicker(idx) {
    this.fromTimepickerTargets[idx]._flatpickr.clear()
    this.toTimepickerTargets[idx]._flatpickr.clear()
  }

  private disableTimepickers() {
    this.timepickerContainerTargets.forEach((el) => {
      el.getElementsByClassName("Nanny-Availability-Timepicker-Container")[0].className =
        "Nanny-Availability-Timepicker-Container--disabled"
      this.timepickerInputContainerTargets.forEach((input) => {
        for (let child of input.children) {
          child.disabled = true
        }
      })
    })
  }

  private enableTimepickers() {
    this.timepickerContainerTargets.forEach((el) => {
      const disabledTimepicker = el.getElementsByClassName(
        "Nanny-Availability-Timepicker-Container--disabled"
      )[0]
      if (disabledTimepicker)
        disabledTimepicker.className = "Nanny-Availability-Timepicker-Container"
      this.timepickerInputContainerTargets.forEach((input) => {
        for (let child of input.children) {
          child.disabled = false
        }
      })
    })
  }

  private setTimeConstraints() {
    if (this.multipleTimepickersVisible) {
      const timeComponents = this.toTimepickerTargets[0].value.split(":")
      let minHour = parseInt(timeComponents[0])
      let minMinutes = parseInt(timeComponents[1]) + 30
      if (minMinutes == 60) {
        minHour += 1
        minMinutes = 0
      }
      this.fromTimepickerTargets[1]._flatpickr.set("minTime", [minHour, minMinutes].join(":"))
      this.fromTimepickerTargets[1]._flatpickr.set("defaultHour", minHour)
      this.fromTimepickerTargets[1]._flatpickr.set("defaultMinute", minMinutes)
    }
  }

  private disableSaveButton() {
    this.availabilitiesSaveTarget.disabled = true
  }

  private enableSaveButton() {
    this.availabilitiesSaveTarget.disabled = false
  }

  private hideTimepickerRemoveIcons() {
    this.timepickerRemoveIconTargets.forEach((el) => {
      el.hidden = true
    })
  }

  private submit = (event) => {
    this.offcanvas.hide()
  }
}
