import {
  Appearance,
  loadStripe,
  PaymentIntentResult,
  Stripe,
  StripeElements,
} from "@stripe/stripe-js"
import { Controller } from "@hotwired/stimulus"
import Modal from "bootstrap/js/dist/modal"

interface SubmitEvent extends Event {
  submitter: HTMLButtonElement
}

export default class extends Controller {
  private stripe: Stripe
  private elements: StripeElements
  private submitButton: HTMLButtonElement
  private boundClearErrors

  static targets = [
    "paymentElement",
    "container",
    "loader",
    "tip",
    "coupon",
    "applyCouponButton",
    "confirmModalCloseButton",
    "error",
    "cardSubmit",
    "cardSubmitConfirm",
    "cardSubmitConfirmModal",
  ]
  static values = { type: String }

  declare paymentElementTarget: HTMLElement
  declare containerTarget: HTMLElement
  declare hasContainerTarget: boolean
  declare loaderTarget: HTMLElement
  declare hasLoaderTarget: boolean
  declare tipTarget: HTMLInputElement
  declare hasTipTarget: boolean
  declare couponTarget: HTMLInputElement
  declare applyCouponButton: HTMLButtonElement
  declare confirmModalCloseButtonTarget: HTMLElement
  declare hasCouponTarget: boolean
  declare errorTarget: HTMLSpanElement
  declare cardSubmitTarget: HTMLButtonElement
  declare hasCardSubmitTarget: boolean
  declare cardSubmitConfirmTarget: HTMLButtonElement
  declare hasCardSubmitConfirmTarget: boolean
  declare cardSubmitConfirmModalTarget: HTMLElement
  declare hasCardSubmitConfirmModalTarget: boolean

  modal: Modal

  initialize() {
    this.boundClearErrors = this.clearErrors.bind(this)
  }

  async connect() {
    // NOTE: To best leverage Stripe’s advanced fraud functionality, include this script on every page,
    //       not just the checkout page. This allows Stripe to detect suspicious behavior that may be
    //       indicative of fraud as customers browse your website.
    this.stripe = await loadStripe(process.env.STRIPE_PUBLISHABLE_KEY)
    this.submitButton = this.hasCardSubmitConfirmTarget
      ? this.cardSubmitConfirmTarget
      : this.cardSubmitTarget
    this.elements = this.stripe.elements({
      clientSecret: this.data.get("clientSecret"),
      fonts: [this.elementFont],
      appearance: this.paymentElementAppearance,
    })

    this.element.addEventListener("show.bs.collapse", this.boundClearErrors)

    const paymentElement = this.elements.create("payment", { style: this.elementStyle })
    paymentElement.mount(this.paymentElementTarget)

    paymentElement.on("ready", this.onPaymentElementReady.bind(this))
    paymentElement.on("change", this.onPaymentElementChange.bind(this))
  }

  disconnect() {
    this.element.removeEventListener("show.bs.collapse", this.boundClearErrors)
  }

  submit = async (event: SubmitEvent) => {
    event.preventDefault()

    // disable & activate spinner on submit button
    this.submitButton.disabled = true
    this.submitButton.firstElementChild.classList.toggle("visually-hidden")

    // prevent the confirm submit modal from closing
    if (this.hasCardSubmitConfirmModalTarget) {
      this.cardSubmitConfirmModalTarget.setAttribute("data-bs-backdrop", "static")
      this.cardSubmitConfirmModalTarget.setAttribute("data-bs-keyboard", "false")
      this.confirmModalCloseButtonTarget.setAttribute("disabled", "true")
    }

    this.clearErrors()

    const jobId = this.data.get("jobId")
    const returnUrl = jobId
      ? `${window.location.origin}/jobs/${jobId}/payment_complete`
      : `${window.location.origin}/subscription/payment_complete`
    let response: PaymentIntentResult

    // apply a tip
    if (this.hasTipTarget && this.tipTarget.value) {
      const tipResult = await this.applyTip()
      if (!tipResult) return
    }

    if (
      event.submitter &&
      event.submitter.getAttribute("data-submit-without-stripe") == "immediately"
    ) {
      // do a regular submission without stripe payment element
      // this will use the default credit card on the primary parent account
      const preferredPaymentMethodId = this.data.get("preferredPaymentMethodId")
      response = await this.stripe.confirmCardPayment(this.data.get("clientSecret"), {
        payment_method: preferredPaymentMethodId,
      })
      if (!response.error) {
        // stripe doesn't redirect on `confirmCardPayment` like it does with `confirmPayment` so we need to do so manually
        window.location.replace(`${returnUrl}?redirect_status=${response.paymentIntent.status}`)
      }
    } else if (this.data.get("isPaymentIntent") == "1") {
      // confirm the payment intent with the stripe payment element
      response = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: returnUrl,
        },
      })
    } else {
      // confirm the setup intent with the stripe payment element
      // used when we want to save the payment method but invoice amount is 0
      response = await this.stripe.confirmSetup({
        elements: this.elements,
        confirmParams: {
          return_url: returnUrl,
        },
      })
    }

    if (response.error) {
      this.handleError(`${response.error.message} (error code: ${response.error.code})`)
    }
  }

  reEnableSubmit() {
    this.submitButton.disabled = false
    this.submitButton.firstElementChild.classList.add("visually-hidden")

    if (this.hasCardSubmitConfirmModalTarget) {
      this.modal = Modal.getInstance(this.cardSubmitConfirmModalTarget)
      this.modal.hide()
    }
  }

  handleError(message) {
    this.reEnableSubmit()

    const errorEl: HTMLSpanElement = this.errorTarget
    errorEl.textContent = message
  }

  clearErrors() {
    const errorEl: HTMLSpanElement = this.errorTarget
    errorEl.textContent = ""
  }

  onPaymentElementReady(event) {
    if (this.hasContainerTarget && this.hasLoaderTarget) {
      this.containerTarget.classList.remove("invisible")
      this.loaderTarget.classList.add("invisible")
    }
  }

  onPaymentElementChange(event) {
    if (this.hasCardSubmitTarget) {
      this.cardSubmitTarget.disabled = !event.complete
    }
  }

  async applyTip() {
    const jobId = this.data.get("jobId")
    const url = `/jobs/${jobId}/apply_tip`
    const response = await fetch(url, {
      headers: {
        "X-CSRF-Token": this.csrfToken,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
      body: JSON.stringify({ tip: this.tipTarget.value }),
    })
    if (!response.ok) {
      this.handleError("Error applying tip, payment was not completed")
      return false
    } else {
      return true
    }
  }

  get csrfToken() {
    return document.querySelector("[name='csrf-token']").content
  }

  get elementFont() {
    return {
      cssSrc:
        "https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap",
    }
  }

  get elementStyle() {
    return {
      base: {
        fontSize: "16px",
        fontSmoothing: "antialiased",
        fontWeight: "400",
        fontFamily: "Montserrat",
      },
    }
  }

  get paymentElementAppearance(): Appearance {
    return {
      variables: {
        fontFamily: "Montserrat",
      },
      rules: {
        ".Label": {
          color: "#747474", // $neutral-grey-04
          fontSize: "1rem",
          fontWeight: "500",
          marginBottom: "0.5rem",
        },
      },
    }
  }
}
