import { Controller } from "@hotwired/stimulus"
import * as R from "ramda"
import { useMutation } from "stimulus-use"
import { SELECTED_EVENT } from "./invoker_controller"

export default class extends Controller {
  static targets = ["content", "input", "childInput"]
  static values = { for: String, value: String, toggled: Boolean }

  declare inputTarget: HTMLInputElement
  declare childInputTargets: HTMLInputElement[]
  declare contentTarget: HTMLElement
  declare contentTargets: HTMLElement[]
  declare hasInputTarget: boolean
  declare forValue: string
  declare valueValue: string
  declare toggledValue: boolean

  connect() {
    if (this.hasInputTarget) {
      if (this.inputTarget.value && !this.toggledValue) {
        this.toggleCSSclasses()
      }
      useMutation(this, {
        element: this.inputTarget,
        attributes: true,
        attributeFilter: ["value"],
        attributeOldValue: true,
      })
    }
  }

  mutate(entries: any) {
    entries.forEach((entry) => {
      const selectedEvent = new CustomEvent(SELECTED_EVENT, {
        bubbles: true,
        detail: entry,
      })
      this.inputTarget.dispatchEvent(selectedEvent)
    })
  }

  toggle = (event: MouseEvent) => {
    if (event) event.stopPropagation()

    if (this.hasInputTarget) {
      if (this.inputTarget.value) {
        this.inputTarget.toggleAttribute("value")
      } else {
        this.inputTarget.setAttribute("value", this.forValue)
      }
    }
    this.toggleCSSclasses()
  }

  toggleCSSclasses = () => {
    this.toggledValue = !this.toggledValue
    this.contentTargets.forEach((el: HTMLElement) => {
      const classes = R.split(" ", el.dataset.toggleClasses)
      classes.forEach((cl: string) => el.classList.toggle(cl))
    })
  }

  stop = (event: PointerEvent) => {
    event.stopImmediatePropagation()
  }
}
