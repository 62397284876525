import { Controller } from "@hotwired/stimulus"
import Choices from "choices.js"
import * as R from "ramda"

export default class extends Controller {
  static targets = ["select"]

  declare selectTarget: HTMLInputElement

  connect() {
    const options = JSON.parse(this.data.get("options")) || {}
    if (!options.itemSelectText)
      options.classNames = R.mergeDeepRight(options.classNames, { item: "choices__item pe-0" })

    new Choices(
      this.selectTarget,
      R.mergeDeepRight(
        {
          callbackOnCreateTemplates: function (template) {
            return {
              item: ({ classNames }, data) => {
                return template(`
              <div class="${classNames.item} ${
                  data.highlighted ? classNames.highlightedState : classNames.itemSelectable
                } ${data.placeholder ? classNames.placeholder : ""}" data-item data-id="${
                  data.id
                }" data-value="${data.value}" data-icon="${data.customProperties.icon}" ${
                  data.active ? 'aria-selected="true"' : ""
                } ${data.disabled ? 'aria-disabled="true"' : ""}>
                ${data.label}
              </div>
            `)
              },
              choice: ({ classNames }, data) => {
                return template(`
              <div class="${classNames.item} ${classNames.itemChoice} ${
                  data.disabled ? classNames.itemDisabled : classNames.itemSelectable
                }" data-select-text="${this.config.itemSelectText}" data-choice ${
                  data.disabled
                    ? 'data-choice-disabled aria-disabled="true"'
                    : "data-choice-selectable"
                } data-id="${data.id}" data-value="${data.value}" ${
                  data.customProperties.icon && "data-icon=" + data.customProperties.icon
                } ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'}>
              ${data.label}
            </div>
          `)
              },
            }
          },
          classNames: {
            containerInner: "form-control",
          },
        },
        options
      )
    )
  }
}
