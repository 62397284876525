import { Controller } from "@hotwired/stimulus"
import * as R from "ramda"

export default class extends Controller {
  private boundOnClickQualificationGroupHandler
  static targets = ["qualificationGroup", "qualificationGroupCheckbox", "qualification"]

  declare qualificationGroupTarget: HTMLButtonElement
  declare qualificationGroupCheckboxTarget: HTMLElement
  declare qualificationTargets: HTMLInputElement

  initialize() {
    this.boundOnClickQualificationGroupHandler = this.onClickQualificationGroup.bind(this)
  }

  connect() {
    this.qualificationGroupTarget.addEventListener(
      "click",
      this.boundOnClickQualificationGroupHandler
    )
  }

  disconnect() {
    this.qualificationGroupTarget.removeEventListener(
      "click",
      this.boundOnClickQualificationGroupHandler
    )
  }

  onClickQualificationGroup = () => {
    const qualificationIsChecked = R.any((el) => el.checked, this.qualificationTargets)
    const qualificationGroupIsExpanded =
      this.qualificationGroupTarget.getAttribute("aria-expanded") == "true"
    const checkedClass = this.data.get("checkedClass")
    if (qualificationIsChecked || qualificationGroupIsExpanded) {
      this.qualificationGroupCheckboxTarget.classList.add(checkedClass)
    } else {
      this.qualificationGroupCheckboxTarget.classList.remove(checkedClass)
    }
  }
}
