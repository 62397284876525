import { Controller } from "@hotwired/stimulus"
import * as R from "ramda"

export default class extends Controller {
  static targets = ["input"]

  declare inputTarget: HTMLInputElement

  togglePasswordView() {
    const type = R.prop("type", this.inputTarget) === "password" ? "text" : "password"
    this.inputTarget.setAttribute("type", type)
  }
}
