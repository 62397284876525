import { Controller } from "@hotwired/stimulus"
import Offcanvas from "bootstrap/js/dist/offcanvas"

export default class extends Controller {
  private boundOnClickLink
  private offCanvas

  static targets = ["menu"]

  declare menuTarget: HTMLElement

  initialize() {
    this.boundOnClickLink = this.onClickMenuLink.bind(this)
    this.offCanvas = new Offcanvas(this.menuTarget)
  }

  connect() {
    this.menuTarget
      .querySelectorAll("a")
      .forEach((linkEl) => linkEl.addEventListener("click", this.boundOnClickLink))
  }

  disconnect() {
    this.menuTarget
      .querySelectorAll("a")
      .forEach((linkEl) => linkEl.removeEventListener("click", this.boundOnClickLink))
  }

  onClickMenuLink = (event) => {
    this.offCanvas.hide()
  }
}
