import { Controller } from "@hotwired/stimulus"
import Popover from "bootstrap/js/dist/popover"

export default class extends Controller {
  popover: Popover
  boundHidePopover: any

  static targets = ["input", "copyButton", "shareButton"]

  declare inputTarget: HTMLInputElement
  declare copyButtonTarget: HTMLButtonElement
  declare shareButtonTarget: HTMLButtonElement

  initialize() {
    this.boundHidePopover = this.hidePopover.bind(this)
  }

  connect() {
    if (navigator.share) {
      this.shareButtonTarget.classList.remove("d-none")
    } else {
      this.copyButtonTarget.classList.remove("d-none")
    }

    this.popover = new Popover(this.copyButtonTarget)
    this.copyButtonTarget.addEventListener("focusout", this.boundHidePopover)
  }

  disconnect() {
    this.copyButtonTarget.removeEventListener("focusout", this.boundHidePopover)
  }

  copyLink() {
    navigator.clipboard.writeText(this.inputTarget.value)
    this.popover.show()
    setTimeout(() => this.popover.hide(), 1500)
  }

  shareLink() {
    navigator.share({ url: this.inputTarget.value })
  }

  hidePopover() {
    this.popover.hide()
  }
}
