document.addEventListener("turbo:load", () => {
  var confirmPaymentModal = document.getElementById("confirm-send-payment")
  if (confirmPaymentModal) {
    confirmPaymentModal.addEventListener("show.bs.modal", function (event) {
      var modalTrigger = event.relatedTarget
      var submitBtn = document.getElementById("confirmPaymentSubmitBtn")
      submitBtn.dataset.submitWithoutStripe = modalTrigger.getAttribute(
        "data-submit-without-stripe"
      )
    })
  }
})
