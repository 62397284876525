import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = ["nannyId", "jobCheckbox"]

  declare nannyIdTarget: HTMLInputElement
  declare jobCheckboxTargets: HTMLInputElement[]

  async setForm() {
    const nannyId = this.nannyIdTarget.value
    const jobId = this.data.get("jobId")
    const url = `/jobs/${jobId}/set_form`

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/vnd.turbo-stream.html",
        "X-CSRF-Token": document.head.querySelector("[name='csrf-token']").content,
      },
      body: JSON.stringify({ parent_confirm_nanny_form: { nanny_id: nannyId } }),
    })
      .then((response) => response.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }

  toggleJob(e) {
    this.jobCheckboxTargets.forEach((checkbox) => {
      if (e.currentTarget.contains(checkbox)) {
        checkbox.checked = !checkbox.checked
      }
    })
  }

  selectAllJobs() {
    this.jobCheckboxTargets.forEach((checkbox) => {
      checkbox.parentElement.classList.add("ParentsBookings-JobBlock--selected")
      checkbox.checked = true
    })
  }
}
