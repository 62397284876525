import { Controller } from "@hotwired/stimulus"
import { navigator } from "@hotwired/turbo"

export default class extends Controller {
  private boundShowLoader

  static targets = ["loader", "loadingContent", "loadTrigger"]

  declare loaderTarget: HTMLElement
  declare loadingContentTarget: HTMLElement
  declare loadTriggerTarget: HTMLElement

  initialize() {
    this.boundShowLoader = this.showLoader.bind(this)
  }

  connect() {
    this.loadTriggerTarget.addEventListener("change", this.boundShowLoader)
  }

  disconnect() {
    this.loadTriggerTarget.removeEventListener("change", this.boundShowLoader)
  }

  showLoader = (event) => {
    this.loaderTarget.classList.remove("invisible")
    this.loadingContentTarget.classList.add("invisible")
  }
}
