import { Controller } from "@hotwired/stimulus"
import { navigator } from "@hotwired/turbo"

export default class extends Controller {
  private boundOnChangeInput

  static targets = ["input", "form"]

  declare inputTarget: HTMLInputElement
  declare formTarget: HTMLFormElement

  initialize() {
    this.boundOnChangeInput = this.onChangeInput.bind(this)
  }

  connect() {
    this.inputTarget.addEventListener("change", this.boundOnChangeInput)
  }

  disconnect() {
    this.inputTarget.removeEventListener("change", this.boundOnChangeInput)
  }

  onChangeInput = (event) => {
    navigator.submitForm(this.formTarget)
  }
}
