import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = ["jobs"]

  declare jobsTargets: [HTMLElement]

  selected(event) {
    const checkbox = event.target
    const checked = checkbox.checked
    const id = checkbox.value
    const confirm = this.jobsTargets.find((e) => e.id === `accept_job_${id}`)
    checked ? confirm.classList.remove("d-none") : confirm.classList.add("d-none")
  }
}
