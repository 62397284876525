import { Controller } from "@hotwired/stimulus"

export const SELECTED_EVENT = "invoker:option-selected"

export default class extends Controller {
  static targets = ["child"]
  static values = { func: String, controller: String }

  declare childTargets: HTMLElement[]
  declare controllerValue: string
  declare funcValue: string
  connect() {
    this.element.addEventListener(SELECTED_EVENT, this.invoke.bind(this))
  }

  invoke = (event: MouseEvent) => {
    this.childTargets.forEach((elm) => {
      this.application
        .getControllerForElementAndIdentifier(elm, this.controllerValue)
        [this.funcValue](event)
    })
  }

  get SELECTED_EVENT() {
    return SELECTED_EVENT
  }
}
